/**
 * @Author: HS
 * @Date: 2021/6/17 10:12 上午
 * @Description: 登录
 * @LastEditTime: 2021/6/17 10:12 上午
 * @LastEditors:
 * @FilePath: src/views/Login/Index/Index.ts
*/
import { Component, Vue, Provide } from 'vue-property-decorator';


@Component({
    components: {
    },
})
export default class Common extends Vue {
    @Provide() infoImg: number = 0;
    // @Provide() windowScreenWidth: number = document.body.clientWidth; //动态的屏幕宽度
    @Provide() windowScreenWidth: any = (document.body.style as any ).zoom; //动态的屏幕宽度

    created() {
        this.imgsChageFn();
    }

    /**
     * @Author HS
     * @Date 2021/6/30 6:33 下午
     * @Description: 切换图片
     * @Params: null
     * @Return: null
    */
    imgsChageFn() {
        let time = setTimeout(() => {
            if (this.infoImg == 0) {
                this.infoImg = 1
                clearTimeout(time)
                this.imgsChageFn()
                return
            }
            if (this.infoImg == 1) {
                this.infoImg = 0
                clearTimeout(time)
                this.imgsChageFn()
                return
            }
        }, 4000);
    }

    /**
     * @Author HS
     * @Date 2021/10/19 6:11 下午
     * @Description: 打开APP二维码
     * @Params: null
     * @Return: null
     */
    appShow() {
        const _this: any = this;
        _this.$store.commit('SET_APPSHOW', true)
    }


    /**
     * @Author HS
     * @Date 2021/9/3 5:28 下午
     * @Description: 页面跳转
     * @Params: { string ：link - true } [跳转地址]
     * @Params: { boolean ：openLink - false } [是否打开新窗口]
     * @Return: null
     */
    goRoute(link: string, openLink: boolean) {
        const _this: any = this;
        if (_this.$base.isNull(link)) return
        if (openLink) {
            window.open(link, "_blank");
            return;
        }
        window.open(link);
    }


    /**
     * @Author HS
     * @Date 2021/7/9 3:56 下午
     * @Description: Mine页面跳转
     * @Params: { string ：path - true } [跳转地址]
     * @Params: { boolean ：isToken - false } [是否需要登陆]
     * @Return: null
     */
    goMine(path: string, isToken: boolean) {
        const _this: any = this;
        if (_this.$base.isLogin() && isToken) {
            const link = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path, path)
            _this.$base.goRouter(link)
            return
        }
        _this.$base.goRouter(_this.$MineConfig[path].path)
    }
    /**
     * @Author HS
     * @Date 2021/7/9 3:56 下午
     * @Description: Index页面跳转
     * @Params: { string ：path - true } [跳转地址]
     * @Params: { boolean ：isToken - false } [是否需要登陆]
     * @Return: null
     */
    goIndex(path: string, isToken: boolean) {
        const _this: any = this;
        if (_this.$base.isLogin() && isToken) {
            const link = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path, path)
            _this.$base.goRouter(link)
            return
        }
        _this.$base.goRouter(_this.$IndexConfig[path].path)
    }

}
